import React from 'react';
import { Outlet } from 'react-router-dom';
import DefaultHeader from '../shared/DefaultHeader';
import DefaultFooter from "../shared/DefaultFooter";

const DefaultLayout = () => {

  return (
    <>
      <DefaultHeader />
      <Outlet />
      <DefaultFooter />
    </>
  );
};

export default DefaultLayout;