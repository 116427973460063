import React from 'react';
import Button from "../form/Button";
import { classNames } from "../../helpers/classNames";

const InterventionItem = ({ data }) => {
  return (
    <>
      <div className={classNames(
        "relative overflow-hidden rounded-3xl p-4 flex",
        data?.styleType === 1 ? "bg-prontopsy-blue" :
          data?.styleType === 2 ? "bg-prontopsy-pink" :
            data?.styleType === 3 ? "bg-prontopsy-yellow" :
              "bg-prontopsy-blue"
      )}>
        <div className="border border-white w-full rounded-2xl py-10 px-6 text-center">
          {data.value && (
            <div className="w-24 aspect-square bg-white/75 rounded-full mx-auto mb-4 flex items-center justify-center">
              <div className="text-5xl font-bold text-black">{data.value}</div>
            </div>
          )}
          {data.description && (<div className="text-lg text-white mb-6 w-full max-w-xs mx-auto uppercase !leading-tight" dangerouslySetInnerHTML={{ __html: data.description }}></div>)}
          {data.name && (<h4 className="font-UnisonPro font-bold italic text-xl text-white !leading-[1.1]" dangerouslySetInnerHTML={{ __html: data.name }}></h4>)}
          {data?.action && (
            <div className="flex justify-center mt-8">
              <Button
                buttonHasLink={true}
                buttonLink={data?.action?.link}
                buttonLabel={data?.action?.label}
                buttonClasses={"!h-12 !bg-white/80 !text-black hover:!bg-white !px-12"}
                buttonLabelClasses={"!text-base"}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InterventionItem;