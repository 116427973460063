import React, { useEffect, useRef } from 'react';
import { register } from 'swiper/element/bundle';
import ConsultantItem from "../../single/ConsultantItem";
import Button from "../../form/Button";
import ImageUser01 from "../../../assets/images/user-01.webp";
import ImageUser02 from "../../../assets/images/user-02.webp";
import ImageUser03 from "../../../assets/images/user-03.webp";
register();

const Consultants = () => {
  const swiperElRef = useRef(null);

  useEffect(() => {
    const swiperEl = document.querySelector('swiper-container.consultantSlider');
    const swiperParams = {
      loop: true,
      navigation: {
        nextEl: '.consultantSlider-next',
        prevEl: '.consultantSlider-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          spaceBetween: 0,
          centeredSlides: true,
          loop: true
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: false,
          loop: true
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 0,
          centeredSlides: true,
          loop: false
        },
      },
      on: {
        init() { },
      },
    };
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }, []);

  const consultantData = {
    title: "Available<br/><span class='text-prontopsy-pink'>Consultants</span>",
    consultant: [
      {
        _id: 1,
        name: "Giuseppe Del Vescovo",
        image: ImageUser03,
        specialization: "Psychologist and Psychotherapist",
        intervention: "Clinical Psychology",
        bio: "<p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of wil foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of</p>",
        language: [
          { _id: 1, name: "English", },
        ],
        nationality: {
          name: "Italian",
          code: "it"
        },
        rate: {
          currency: "INR",
          value: "500.00"
        },
        isOnline: "online",
        isFavorite: true,
        rating: {
          value: 5,
          reviews: 1
        },
        actions: {
          call: {
            isLink: false,
            click: () => { },
            label: "Call Now"
          },
          booking: {
            isLink: false,
            click: () => { },
            label: "Book a consult"
          },
          email: {
            isLink: true,
            link: "mailto:info@prontopsy.com",
            label: "Email me"
          }
        }
      },
      {
        _id: 2,
        name: "Alexander",
        image: ImageUser02,
        specialization: "Psychologist and Psychotherapist",
        intervention: "Clinical Psychology",
        bio: "<p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of wil foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of</p>",
        language: [
          { _id: 1, name: "English", },
        ],
        nationality: {
          name: "Italian",
          code: "it"
        },
        rate: {
          currency: "INR",
          value: "500"
        },
        isOnline: "busy",
        isFavorite: true,
        rating: {
          value: 5,
          reviews: 1
        },
        actions: {
          call: {
            isLink: false,
            click: () => { },
            label: "Call Now"
          },
          booking: {
            isLink: false,
            click: () => { },
            label: "Book a consult"
          },
          email: {
            isLink: true,
            link: "mailto:info@prontopsy.com",
            label: "Email me"
          }
        }
      },
      {
        _id: 3,
        name: "George",
        image: ImageUser01,
        specialization: "Psychologist and Psychotherapist",
        intervention: "Clinical Psychology",
        bio: "<p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of wil foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of</p>",
        language: [
          { _id: 1, name: "English", },
        ],
        nationality: {
          name: "Italian",
          code: "it"
        },
        rate: {
          currency: "INR",
          value: "500"
        },
        isOnline: "offline",
        isFavorite: true,
        rating: {
          value: 5,
          reviews: 1
        },
        actions: {
          call: {
            isLink: false,
            click: () => { },
            label: "Call Now"
          },
          booking: {
            isLink: false,
            click: () => { },
            label: "Book a consult"
          },
          email: {
            isLink: true,
            link: "mailto:info@prontopsy.com",
            label: "Email me"
          }
        }
      },
    ]
  }

  return (
    <>
      <section className="relative py-24 flex items-center bg-neutral-100">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full text-center">
            {consultantData?.title && (
              <h2 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: consultantData?.title }}></h2>
            )}
          </div>
          {consultantData?.consultant?.length > 0 && (
            <div className="relative w-full max-w-7xl mx-auto mt-10">
              <swiper-container
                ref={swiperElRef}
                class="consultantSlider -mx-5"
                init="false"
              >
                {consultantData?.consultant?.map((item) => (
                  <swiper-slide key={item._id} className="">
                    <ConsultantItem data={item} />
                  </swiper-slide>
                ))}
              </swiper-container>
              <div className="relative flex justify-center !mt-8">
                <div className="flex justify-center space-x-2 px-4">
                  <Button
                    buttonClasses={'consultantSlider-prev !px-0 aspect-square !rounded-full !bg-transparent !text-neutral-700 border border-neutral-700 !h-14 !text-2xl'}
                    buttonType={'button'}
                    buttonIcon={"fa-light fa-chevron-left"}
                    buttonIconPosition={"left"}
                  />
                  <Button
                    buttonClasses={'consultantSlider-next !px-0 aspect-square !rounded-full !bg-transparent !text-neutral-700 border border-neutral-700 !h-14 !text-2xl'}
                    buttonType={'button'}
                    buttonIcon={"fa-light fa-chevron-right"}
                    buttonIconPosition={"left"}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Consultants;