import React from 'react';
import InterventionItem from "../../single/InterventionItem";
import Button from "../../form/Button";

const AreaOfIntervention = () => {
  const interventionData = {
    title: "Area of<br/><span class='text-prontopsy-pink'>Intervention</span>",
    subtitle: "<p>What kind of Psychologist are you looking for?</p>",
    intervention: [
      {
        _id: 1,
        styleType: 1,
        value: 6,
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details"
        }
      },
      {
        _id: 2,
        styleType: 2,
        value: 6,
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details"
        }
      },
      {
        _id: 2,
        styleType: 3,
        value: 6,
        name: "Community Psychology",
        description: "<p>Consultants are responding to users</p>",
        action: {
          link: "/",
          label: "Details"
        }
      },
    ],
    action: {
      link: "/",
      label: "View All"
    }
  }
  return (
    <>
      <section className="relative py-20 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {interventionData?.title && (
              <h2 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: interventionData?.title }}></h2>
            )}
            {interventionData?.subtitle && (
              <div className="text-lg text-neutral-700 space-y-4 mt-6" dangerouslySetInnerHTML={{ __html: interventionData?.subtitle }}></div>
            )}
          </div>
          {interventionData?.intervention?.length > 0 && (
            <div className="grid grid-cols-3 gap-8 mt-16">
              {interventionData?.intervention?.map((item, index) => (
                <InterventionItem key={index} data={item} />
              ))}
            </div>
          )}
          {interventionData?.action && (
            <div className="flex items-center justify-center mt-20">
              <Button
                buttonHasLink={true}
                buttonLink={interventionData?.action?.link}
                buttonLabel={interventionData?.action?.label}
                buttonClasses={"!h-12 !bg-prontopsy-blue hover:!bg-prontopsy-pink !px-12"}
                buttonLabelClasses={"!text-base"}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default AreaOfIntervention;