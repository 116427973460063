import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import NavigationPanel from "./NavigationPanel";
import Logo from "../assets/images/logo.svg";
import { classNames } from "../helpers/classNames";

const DefaultHeader = () => {
  const [scrolled, setScrolled] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const menuData = [
    { _id: 1, name: "Home", link: "/" },
    { _id: 2, name: "Find a Psychologist", link: "/find-psychologist" },
    { _id: 3, name: "Community Psychology", link: "/community-psychology" },
    { _id: 4, name: "Area of Intervention", link: "/area-of-intervention" },
    { _id: 5, name: "About us", link: "/about-us" },
    { _id: 6, name: "Frequently Asked Questions", link: "/frequently-asked-questions" },
    { _id: 7, name: "Blog", link: "/blog" },
    { _id: 8, name: "Leave a Feedback", link: "/feedback" },
    { _id: 9, name: "Book an Interview dial", link: "/book-an-interview" },
    { _id: 10, name: "Contact us", link: "/contact-us" },
  ]

  useEffect(() => {
    function handleScroll() {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={classNames(
        "fixed top-0 left-0 w-full z-10 bg-transparent flex items-center transition-all duration-200",
        scrolled ? "h-24 bg-white shadow-md" : "h-32"
      )}>
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className={classNames("relative transition-all duration-200", scrolled ? "h-16" : "h-24")}>
            <Link to={"/"} className="flex w-full h-full">
              <Image
                src={Logo}
                alt={"Prontopsy Logo"}
                effect={"blur"}
                className={"object-contain"}
              />
            </Link>
          </div>
          <div className="flex ml-auto space-x-3">
            <Button
              buttonHasLink={true}
              buttonLink={"/signin"}
              buttonLabel={"Signin"}
              buttonIcon={"fa-light fa-user-plus"}
              buttonIconPosition={"left"}
              buttonClasses={"uppercase text-sm hover:!bg-prontopsy-blue"}
            />
            <Button
              buttonHasLink={false}
              buttonIcon={"fa-solid fa-bars-staggered"}
              buttonIconPosition={"left"}
              buttonClasses={"!px-0 text-xl aspect-square !rounded-full !bg-prontopsy-yellow !text-black hover:!bg-prontopsy-blue hover:!text-prontopsy-yellow"}
              buttonFunction={() => setMenuToggle(true)}
            />
          </div>
        </div>
      </header>
      <NavigationPanel data={menuData} toggle={menuToggle} setToggle={setMenuToggle} />
    </>
  );
};

export default DefaultHeader;