import React from 'react';
import Button from "../../form/Button";

const Register = () => {
  const registerData = {
    title: "Are You A<br/><span class='text-prontopsy-pink'>Registered Psychologist?</span>",
    subtitle: "<p>Start offering online counseling today! Wherever you are in the world, whenever you want, in any field of expertise in which you specialize! Choose how much time to dedicate to your daily consultations. Whether you decide to be online in real time or on booking based on your availability, the more availability you offer and the more users will be able to reach you! A new concept of online psychological counseling is born! Feel free, be Pronto!</p>",
    action: {
      link: "/",
      label: "Signup Now"
    }
  }

  return (
    <>
      <section className="relative py-24 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {registerData?.title && (
              <h2 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: registerData?.title }}></h2>
            )}
            {registerData?.subtitle && (
              <div className="text-lg text-neutral-700 space-y-4 mt-6 max-w-6xl mx-auto" dangerouslySetInnerHTML={{ __html: registerData?.subtitle }}></div>
            )}
          </div>
          {registerData?.action && (
            <div className="flex items-center justify-center mt-20">
              <Button
                buttonHasLink={true}
                buttonLink={registerData?.action?.link}
                buttonLabel={registerData?.action?.label}
                buttonClasses={"!h-12 hover:!bg-prontopsy-blue !px-12"}
                buttonLabelClasses={"!text-base"}
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Register;