import React from 'react';
import NavigationItem from "./NavigationItem";
import { classNames } from "../helpers/classNames";
import Button from "../components/form/Button";

const NavigationPanel = ({ data, toggle, setToggle }) => {
  return (
    <>
      <div className={classNames(
        "fixed top-0 right-0 w-full h-screen z-[12] max-w-96 bg-prontopsy-blue flex flex-col transition-all duration-200 shadow-2xl shadow-black",
        toggle ? "translate-x-0 opacity-100 visible" : "translate-x-full opacity-0 invisible"
      )}>
        <div className="flex items-center justify-between py-4 px-6 border-b border-white/10">
          <div className="text-xl uppercase text-white font-UnisonPro font-light">Menu</div>
          <Button
            buttonHasLink={false}
            buttonIcon={"fa-light fa-xmark"}
            buttonIconPosition={"left"}
            buttonClasses={"!px-0 aspect-square !bg-transparent !rounded-full hover:!bg-prontopsy-lightblue"}
            buttonFunction={() => setToggle(false)}
          />
        </div>
        {data?.length > 0 && (
          <div className="space-y-1 flex-grow overflow-auto scrollbar">
            {data?.map((item, index) => (
              <NavigationItem key={index} data={item} />
            ))}
          </div>
        )}
      </div>
      <div
        className={classNames(
          "fixed top-0 right-0 w-full h-screen z-[11] bg-white transition-all duration-200 cursor-pointer",
          toggle ? "opacity-80 visible" : "opacity-0 invisible"
        )}
        onClick={() => setToggle(false)}
      ></div>
    </>
  );
};

export default NavigationPanel;