import React from 'react';

const StatisticsItem = ({ data }) => {
  return (
    <>
      <div className="relative bg-white rounded-lg aspect-[4/3] flex flex-col items-center justify-center py-6 px-6 text-center gap-3">
        {data.value && (<div className="font-Robo text-5xl text-prontopsy-blue">{data.value}</div>)}
        {data.name && (<div className="font-UnisonPro font-bold !leading-none text-prontopsy-pink italic uppercase text-xl">{data.name}</div>)}
      </div>
    </>
  );
};

export default StatisticsItem;