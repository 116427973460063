import React from 'react';
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const ConsultationItem = ({ data }) => {
  return (
    <>
      <div className={classNames(
        "relative overflow-hidden rounded-3xl p-4 flex",
        data?.styleType === 1 ? "bg-prontopsy-blue" :
          data?.styleType === 2 ? "bg-prontopsy-pink" :
            "bg-prontopsy-blue"
      )}>
        <div className="border border-white w-full rounded-2xl py-10 px-6 text-center">
          {data?.icon && (
            <div className="w-20 overflow-hidden mb-8 flex-shrink-0 mx-auto">
              <Image
                src={data?.icon}
                alt={""}
                effect={"blur"}
                className={"object-contain"}
              />
            </div>
          )}
          {data.name && (<h4 className="font-UnisonPro font-bold italic text-xl text-white !leading-[1.1]" dangerouslySetInnerHTML={{ __html: data.name }}></h4>)}
          {data.description && (<div className="text-lg text-white mt-6 w-full max-w-md mx-auto font-light" dangerouslySetInnerHTML={{ __html: data.description }}></div>)}
        </div>
      </div>
    </>
  );
};

export default ConsultationItem;