import React from 'react';
import OnlineFeatureItem from "../../single/OnlineFeatureItem";

import IconSecurity from "../../../assets/images/icon-security.webp";
import IconPrivacy from "../../../assets/images/icon-privacy.webp";
import IconProfessionalism from "../../../assets/images/icon-professionalism-and-expertise.webp";
import IconAccessibility from "../../../assets/images/icon-accessibility.webp";
import IconImmediacy from "../../../assets/images/icon-immediacy.webp";
import IconFlexibility from "../../../assets/images/icon-flexibility.webp";
import IconConvenience from "../../../assets/images/icon-convenience.webp";
import IconComfort from "../../../assets/images/icon-comfort.webp";
import IconProximity from "../../../assets/images/icon-proximity.webp";

const OnlineFeatures = () => {
  const onlineFeaturesData = {
    title: "Online<br/><span class='text-prontopsy-pink'>Is Better</span>",
    onlineFeatures: [
      {
        _id: 1,
        icon: IconSecurity,
        name: "Security",
        description: "<p>We offer safe and reliable payment methods using Stripe technology through the main payment channels Mastercard, Visa, Maestro and American Express. This way you can pay for our services safely and without worries.</p>"
      },
      {
        _id: 2,
        icon: IconPrivacy,
        name: "Privacy",
        description: "<p>Protected interview by advanced technology with end-to-end encryption, which guarantees total security and respect for the privacy of our clients.</p>"
      },
      {
        _id: 3,
        icon: IconProfessionalism,
        name: "Professionalism and Expertise",
        description: "<p>Selected team of registered Psychologist and Psychotherapist experts in different areas of Intervention and Specializations will be ready to answer your needs.</p>"
      },
      {
        _id: 4,
        icon: IconAccessibility,
        name: "Accessibility",
        description: "<p>Now you can receive psychological support wherever you are, regardless of your physical, psychosocial or environmental conditions. Our service offers you the possibility to consult a professional online, from the comfort of your home or from anywhere else.</p>"
      },
      {
        _id: 5,
        icon: IconImmediacy,
        name: "Immediacy",
        description: "<p>We offer professional and prompt responses to our clients' urgent psychological needs, even outside of regular business hours,at night time and on weekends. Our team is always available to provide support when it is needed most.</p>"
      },
      {
        _id: 6,
        icon: IconFlexibility,
        name: "Flexibility",
        description: "<p>Possibility of carrying out psychological interviews through any electronic device, such as a telephone, a mobile phone, a tablet or a computer. You can choose whether to get in touch with the consultant immediately or by appointment.</p>"
      },
      {
        _id: 7,
        icon: IconConvenience,
        name: "Convenience",
        description: "<p>We offer safe and reliable payment methods using Stripe technology through the main payment channels Mastercard, Visa, Maestro and American Express. This way you can pay for our services safely and without worries.</p>"
      },
      {
        _id: 8,
        icon: IconComfort,
        name: "Comfort",
        description: "<p>Thanks to our advanced technology, consults can be carried out from anywhere in the world and in any place you are, from your usual office, by car, outdoors, or from the comfort of your home.</p>"
      },
      {
        _id: 9,
        icon: IconProximity,
        name: "Proximity",
        description: "<p>You can choose from the various communication methods offered the one that makes you feel most at ease, switching from the Chat conversation mode, to the Audio-only mode or to a real Audio-Video conversation.</p>"
      },
    ]
  }

  return (
    <>
      <section className="relative py-20 flex items-center bg-neutral-100">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {onlineFeaturesData?.title && (
              <h2 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: onlineFeaturesData?.title }}></h2>
            )}
          </div>
          {onlineFeaturesData?.onlineFeatures?.length > 0 && (
            <div className="grid grid-cols-3 gap-8 mt-16 w-full">
              {onlineFeaturesData?.onlineFeatures?.map((item, index) => (
                <OnlineFeatureItem key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default OnlineFeatures;