import React from 'react';
import Button from "../components/form/Button";

const DefaultFooter = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const footerData = {
    links: {
      title: "Quick Links",
      data: [
        { _id: 1, name: "What Is Prontopsy?", link: "/" },
        { _id: 2, name: "Faq's", link: "/" },
        { _id: 3, name: "Privacy Policies", link: "/" },
        { _id: 4, name: "About Us", link: "/" },
        { _id: 5, name: "Terms Of Services", link: "/" },
        { _id: 6, name: "Contact Us", link: "/" },
      ]
    },
    about: {
      title: "About us",
      description: "<p>ProntoPsy is the first professional psychological online aid in the world, available 24/7. We are your immediate and prompt online assistance and resource for all your psychological needs.</p>"
    },
    contact: {
      title: "Contact",
      data: [
        { _id: 1, name: "Phone", value: "773-938-8882", type: "tel" },
        { _id: 2, name: "Email", value: "info@prontopsy.com", type: "email" },
      ]
    },
    social: {
      title: "Follow us on",
      data: [
        { _id: 1, name: "Facebook", icon: "fa-brands fa-facebook-f", link: "https://www.facebook.com/" },
        { _id: 2, name: "Twitter", icon: "fa-brands fa-x-twitter", link: "https://twitter.com/" },
        { _id: 3, name: "Google", icon: "fa-brands fa-google", link: "https://www.google.com/" },
        { _id: 4, name: "Instagram", icon: "fa-brands fa-instagram", link: "https://www.instagram.com/" },
      ]
    },
    copyright: "Copyright © " + currentYear + " ProntoPsy. All rights reserved.",
  }
  return (
    <>
      <footer className="relative">
        <div className="relative pt-28 pb-16 bg-prontopsy-blue">
          <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
            <div className="grid grid-cols-20 gap-10 w-full">
              <div className="relative col-span-5 space-y-6">
                {footerData?.links && (
                  <>
                    {footerData?.links?.title && (<h2 className="text-xl text-white font-UnisonPro italic font-bold">{footerData?.links?.title}</h2>)}
                    {footerData?.links?.data?.length > 0 && (
                      <div className="relative space-y-1">
                        {footerData?.links?.data?.map((item, index) => (
                          <div className="flex" key={index}>
                            <Button
                              buttonHasLink={true}
                              buttonLink={item.link}
                              buttonLabel={item.name}
                              buttonClasses={"!justify-start !rounded-none !bg-transparent !border-none !h-10 !px-0 group/link !text-xs !gap-3 hover:!text-prontopsy-pink !overflow-visible"}
                              buttonLabelClasses={"!text-lg group-hover/link:translate-x-2 uppercase !text-white"}
                              buttonIcon={"fa-solid fa-sharp fa-triangle fa-rotate-90"}
                              buttonIconPosition={"left"}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="relative col-span-7 space-y-6">
                {footerData?.about && (
                  <>
                    {footerData?.about?.title && (<h2 className="text-xl text-white font-UnisonPro italic font-bold">{footerData?.about?.title}</h2>)}
                    {footerData?.about?.description && (<div className="text-lg text-white space-y-4 font-light max-w-sm" dangerouslySetInnerHTML={{ __html: footerData?.about?.description }}></div>)}
                  </>
                )}
              </div>
              <div className="relative col-span-8 space-y-16">
                {footerData?.contact && (
                  <div className="space-y-6">
                    {footerData?.contact?.title && (<h2 className="text-xl text-white font-UnisonPro italic font-bold">{footerData?.contact?.title}</h2>)}
                    {footerData?.contact?.data?.length > 0 && (
                      <div className="relative space-y-2">
                        {footerData?.contact?.data?.map((item, index) => (
                          <div className="flex" key={index}>
                            <Button
                              buttonHasLink={true}
                              buttonLink={item.type === 'tel' ? ('tel:' + item.value) : item.type === 'email' ? ('mailto:' + item.value) : item.value}
                              buttonLabel={item.name + ': ' + item.value}
                              buttonClasses={"!justify-start !rounded-none !bg-transparent !border-none !h-10 !px-0 hover:underline underline-offset-4"}
                              buttonLabelClasses={"!text-lg !font-normal"}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {footerData?.social && (
                  <div className="space-y-6">
                    {footerData?.social?.title && (<h2 className="text-xl text-white font-UnisonPro italic font-bold">{footerData?.social?.title}</h2>)}
                    {footerData?.social?.data?.length > 0 && (
                      <div className="relative space-x-4 flex">
                        {footerData?.social?.data?.map((item, index) => (
                          <div key={index}>
                            <Button
                              buttonHasLink={true}
                              buttonTarget={"_blank"}
                              buttonLink={item.link}
                              buttonIcon={item.icon}
                              buttonIconPosition={"left"}
                              buttonClasses={"!px-0 aspect-square !h-12 !text-xl !bg-transparent border border-white !rounded-full hover:!bg-white hover:!text-prontopsy-blue"}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="relative py-10 bg-prontopsy-darkblue">
          <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
            {footerData?.copyright && (
              <div className="flex justify-center w-full">
                <div className="text-base text-white">{footerData?.copyright}</div>
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default DefaultFooter;