import React from 'react';
import ConsultationItem from "../../single/ConsultationItem";

import IconDirectAccess from "../../../assets/images/icon-direct-access.webp";
import IconOnBooking from "../../../assets/images/icon-on-booking.webp";

const Consultation = () => {
  const consultationData = {
    title: "Professional<br/><span class='text-prontopsy-pink'>Consultation</span>",
    consultation: [
      {
        _id: 1,
        styleType: 1,
        icon: IconDirectAccess,
        name: "Direct Access",
        description: "<p>Request an online consultation now, in real time. A psychologist from our team will be there to meet your needs, 24/7.</p>"
      },
      {
        _id: 2,
        styleType: 2,
        icon: IconOnBooking,
        name: "On Booking",
        description: "<p>Book your online appointment and get a personalized psychological consultation, fitting your available schedule. Our highly qualified team is always ready to provide the support you need.</p>"
      },
    ]
  }

  return (
    <>
      <section className="relative py-20 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {consultationData?.title && (
              <h2 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: consultationData?.title }}></h2>
            )}
          </div>
          {consultationData?.consultation?.length > 0 && (
            <div className="grid grid-cols-2 gap-8 mt-16 w-full max-w-6xl mx-auto">
              {consultationData?.consultation?.map((item, index) => (
                <ConsultationItem key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Consultation;