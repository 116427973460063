import React from 'react';
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const FeatureSlideItem = ({ data }) => {
  return (
    <>
      <div className="relative w-full flex-grow flex pt-5 pb-10 px-5">
        <div className="w-full flex items-center pt-16 pb-6 px-6 text-center shadow-xl border border-slate-50 bg-white">
          <div className="flex flex-col w-full h-full items-center border border-neutral-300 px-0 py-8">
            {data?.icon && (
              <div className={classNames(
                "w-24 overflow-hidden rounded-full -mt-20 mb-8 flex-shrink-0",
                data?.styleType === 1 ? "bg-prontopsy-yellow" :
                  data?.styleType === 2 ? "bg-prontopsy-blue" :
                    "bg-prontopsy-yellow"
              )}>
                <Image
                  src={data?.icon}
                  alt={""}
                  effect={"blur"}
                  className={"object-contain"}
                />
              </div>
            )}
            {data.name && (<h4 className="font-UnisonPro font-bold italic text-2xl text-prontopsy-blue !leading-[1.1]" dangerouslySetInnerHTML={{ __html: data.name }}></h4>)}
            {data.description && (<div className="text-lg text-neutral-700 mt-6 w-full max-w-72 mx-auto">{data.description}</div>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureSlideItem;