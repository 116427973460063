import React from 'react';
import Image from "../../elements/Image";
import ImageAbout from "../../../assets/images/what-is-prontopsy.webp";
import Button from "../../form/Button";

const About = () => {
  const aboutData = {
    image: ImageAbout,
    title: "What is <span class='text-prontopsy-pink'>Prontopsy</span>",
    description: "<p>We are a Global Psychological Consultation Portal</p><p>We are a Global Psychological Consultation Portal Whether it's early in the morning or late at night, with Prontopsy you are always connected to expert Psychologists and Psychotherapists from all over the world at a day and time that is convenient for you.You will be the one who decides how long to stay and how much to spend.</p> <p>With over 26 areas of intervention to choose from, you can easily find the expert specializing in your exact need. Over thousand satisfied clients have already awarded our Psychologists and Psychotherapists with top rating and reviews for providing them with      first-rate service.</p>",
    action: {
      link: "/",
      label: "Read More"
    }
  }
  return (
    <>
      <section className="relative py-20 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="grid grid-cols-12 gap-10">
            <div className="relative col-span-7">
              {aboutData?.image && (
                <div className="w-full flex relative">
                  <Image
                    src={aboutData?.image}
                    alt={""}
                    effect={"blur"}
                    className={"object-contain"}
                  />
                </div>
              )}
            </div>
            <div className="relative col-span-5">
              {aboutData?.title && (<h2 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: aboutData?.title }}></h2>)}
              {aboutData?.description && (<div className="text-base text-neutral-700 space-y-4 mt-6" dangerouslySetInnerHTML={{ __html: aboutData?.description }}></div>)}
              {aboutData?.action && (
                <div className="flex items-center !mt-10">
                  <Button
                    buttonHasLink={true}
                    buttonLink={aboutData?.action?.link}
                    buttonLabel={aboutData?.action?.label}
                    buttonClasses={"!h-12 !bg-prontopsy-blue hover:!bg-prontopsy-pink"}
                    buttonLabelClasses={"!text-base"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;