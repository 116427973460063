import React, { useEffect, useState } from 'react';
import Image from "../elements/Image";
import Rating from "../elements/Rating";
import OnlineButton from "../elements/OnlineButton";
import { classNames } from "../../helpers/classNames";
import { getFlag } from "../../util/Flag";
import Button from "../form/Button";
import Favorite from "../form/Favorite";

const ConsultantItem = ({ data }) => {
  const color = {
    online: "bg-prontopsy-green",
    busy: "bg-prontopsy-red",
    offline: "bg-neutral-200",
    "": "bg-neutral-200",
  };

  return (
    <>
      <div className="relative w-full flex py-5 px-[12%]">
        <div className="w-full flex space-x-10 bg-white py-12 px-12 rounded-6xl border border-neutral-100 shadow-md">
          <div className="w-36 flex-shrink-0 flex flex-col items-center space-y-4">
            <div className="relative w-36 aspect-square flex-shrink-0">
              {data?.image && (
                <div className="w-full h-full overflow-hidden rounded-full">
                  <Image
                    src={data?.image}
                    alt={""}
                    effect={"blur"}
                    className={"object-contain"}
                  />
                </div>
              )}
              {data?.isOnline && (
                <div className={classNames(
                  "absolute bottom-1 right-1 w-8 aspect-square rounded-full border-2 border-white",
                  color[data?.isOnline]
                )}></div>
              )}
            </div>
            {data?.rating && (<Rating data={data?.rating} />)}
            {data?.isOnline && (
              <div className="!mt-8">
                <OnlineButton data={data?.isOnline} />
              </div>
            )}
            {data?.rate?.currency && data?.rate?.value && (
              <div className="!mt-8">
                <div className="text-prontopsy-lightblue font-medium text-base">{data?.rate?.currency} {data?.rate?.value} / min</div>
              </div>
            )}
          </div>
          <div className="relative w-full flex-shrink space-y-4">
            <div className="relative space-y-2 pr-10">
              {data?.name && (
                <h2 className="text-2xl font-UnisonPro italic text-prontopsy-blue font-bold">{data?.name}</h2>
              )}
              {data?.specialization && (
                <h4 className="text-xl font-medium text-black">{data?.specialization}</h4>
              )}
              {data?.intervention && (
                <h6 className="text-base text-neutral-700 uppercase">{data?.intervention}</h6>
              )}
              <div className="flex items-center space-x-8 !mt-5">
                {data?.nationality && (
                  <div className="flex items-center space-x-2">
                    <div className="w-5 aspect-[4/3] overflow-hidden flex-shrink-0 flex">
                      <Image
                        src={getFlag(data?.nationality?.code)}
                        alt={""}
                        effect={"blur"}
                        className={"object-contain"}
                      />
                    </div>
                    <div className="text-sm !leading-none text-neutral-700">{data?.nationality?.name}</div>
                  </div>
                )}
                {data?.language?.length > 0 && (
                  <div className="flex items-center space-x-2 text-xl !leading-none text-prontopsy-blue">
                    <i className="fa-regular fa-fw fa-language"></i>
                    <div className="text-sm !leading-none text-neutral-700">
                      {data?.language?.map((item, index) => (
                        <span key={index}>{item.name}</span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {data?.bio && (
              <div className="relative w-full px-6 py-5 border border-neutral-200 rounded-2xl">
                <div className="text-sm text-neutral-400 space-y-3 !leading-normal" dangerouslySetInnerHTML={{ __html: data?.bio }}></div>
              </div>
            )}
            {data?.actions && (
              <div className="relative space-y-4">
                {data?.actions?.call && (
                  <div className="flex justify-center">
                    <Button
                      buttonClasses={"!h-12 !w-full !max-w-md !rounded-lg !bg-prontopsy-green hover:!bg-prontopsy-blue"}
                      buttonHasLink={data?.actions?.call?.isLink}
                      buttonFunction={data?.actions?.call?.click}
                      buttonLabel={data?.actions?.call?.label}
                      buttonLabelClasses={"uppercase !text-base"}
                    />
                  </div>
                )}
                {data?.actions?.booking && (
                  <div className="flex justify-center">
                    <Button
                      buttonClasses={"!h-8 !w-full !max-w-60 !rounded-md !bg-prontopsy-pink hover:!bg-prontopsy-blue"}
                      buttonHasLink={data?.actions?.booking?.isLink}
                      buttonFunction={data?.actions?.booking?.click}
                      buttonLabel={data?.actions?.booking?.label}
                      buttonLabelClasses={"uppercase"}
                    />
                  </div>
                )}
                {data?.actions?.email && (
                  <div className="flex justify-center !mt-2">
                    <Button
                      buttonClasses={"!h-6 !rounded-md !bg-transparent !text-prontopsy-lightblue hover:!text-prontopsy-pink"}
                      buttonHasLink={data?.actions?.email?.isLink}
                      buttonLink={data?.actions?.email?.link}
                      buttonTarget={"_blank"}
                      buttonLabel={data?.actions?.email?.label}
                      buttonLabelClasses={"uppercase !font-medium"}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="absolute z-[1] top-0 right-0 !m-0">
              <Favorite />
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default ConsultantItem;