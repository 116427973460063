import React from 'react';
import Image from "../../elements/Image";
import ImageBanner from "../../../assets/images/banner.webp";
import Button from "../../form/Button";

const MainBanner = () => {
  const bannerData = {
    image: ImageBanner,
    title: "Make A Positive <span class='text-prontopsy-pink'>Change In Your</span> Life Today",
    subtitle: "Talk Online With A Psychologist",
    description: "Get Immediate and Competent Help From Our Selected Experts anywhere in the world.",
    action: {
      link: "/find-psychologist",
      label: "Search Consultants"
    }
  }

  return (
    <>
      <section className="relative pt-44 pb-40 h-screen overflow-hidden z-0 flex items-center">
        <div className="absolute top-0 left-0 w-full h-full z-[-1] flex">
          <Image
            src={bannerData?.image}
            alt={""}
            effect={"blur"}
            className={"object-cover"}
          />
        </div>
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto flex items-center">
          <div className="relative w-full max-w-3xl space-y-5">
            {bannerData?.subtitle && (<h4 className="text-lg text-prontopsy-blue tracking-widest">{bannerData?.subtitle}</h4>)}
            {bannerData?.title && (<h1 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: bannerData?.title }}></h1>)}
            {bannerData?.description && (<div className="text-base text-black max-w-sm">{bannerData?.description}</div>)}
            {bannerData?.action && (
              <div className="flex items-center !mt-10">
                <Button
                  buttonHasLink={true}
                  buttonLink={bannerData?.action?.link}
                  buttonLabel={bannerData?.action?.label}
                  buttonClasses={"!h-12 !px-8 hover:!bg-prontopsy-blue"}
                  buttonLabelClasses={"!text-base"}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default MainBanner;