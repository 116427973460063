import React from 'react';
import Image from "../elements/Image";

const TestimonialItem = ({ data }) => {
  return (
    <>
      <div className="relative w-full flex-grow flex py-10 px-10 pl-24">
        <div className="flex items-center shadow-lg w-full border border-neutral-100 rounded-tl-9xl">
          {data?.image && (
            <div className="w-28 overflow-hidden rounded-full flex-shrink-0 -ml-14 shadow-lg shadow-prontopsy-blue/20">
              <Image
                src={data?.image}
                alt={""}
                effect={"blur"}
                className={"object-contain"}
              />
            </div>
          )}
          <div className="py-10 px-10 flex-shrink w-full">
            {data.name && (<h4 className="font-UnisonPro font-bold italic text-2xl text-prontopsy-blue !leading-[1.1]" dangerouslySetInnerHTML={{ __html: data.name }}></h4>)}
            {data.description && (<div className="text-base text-neutral-700 mt-6 space-y-3" dangerouslySetInnerHTML={{ __html: data.description }}></div>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialItem;