import React from 'react';
import Button from "../components/form/Button";
import { useLocation } from "react-router-dom";
import { classNames } from "../helpers/classNames";

const NavigationItem = ({ data }) => {
  const router = useLocation();
  return (
    <>
      <Button
        buttonHasLink={true}
        buttonLink={data.link}
        buttonLabel={data.name}
        buttonClasses={classNames(
          "!justify-start !rounded-none hover:!bg-prontopsy-pink !border-none !h-12 !px-8",
          data.link === router.pathname ? "!bg-prontopsy-pink" : "!bg-transparent"
        )}
        buttonLabelClasses={"!text-base"}
      />
    </>
  );
};

export default NavigationItem;