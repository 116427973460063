import React from 'react';
import { classNames } from "../../helpers/classNames";

const OnlineButton = ({ data }) => {
  const label = data;
  const textColor = {
    online: "text-prontopsy-green",
    busy: "text-prontopsy-red",
    offline: "text-neutral-400",
    "": "text-neutral-400",
  };
  const dotColor = {
    online: "text-prontopsy-green",
    busy: "text-prontopsy-red",
    offline: "text-neutral-200",
    "": "text-neutral-200",
  };

  return (
    <>
      <div className={classNames(
        "w-full h-10 rounded-md flex items-center justify-center space-x-2 px-4 border border-neutral-200 text-xl",
        dotColor[data]
      )}>
        <i className="fa-solid fa-fw fa-circle"></i>
        <div className={classNames(
          "uppercase font-medium text-lg",
          textColor[data]
        )}>{label}</div>
      </div>
    </>
  );
};

export default OnlineButton;