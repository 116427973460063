import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import AuthLayout from './layouts/AuthLayout';
import DefaultLayout from './layouts/DefaultLayout';
import InnerLayout from "./layouts/InnerLayout";

import Home from './pages/Home';
import Signin from './pages/Signin';



const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='/' element={<DefaultLayout />}>
            <Route path='/' exact element={<Home />} />
          </Route>
          <Route path='/signin' element={<AuthLayout />}>
            <Route path='/signin' exact element={<Signin />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;