import React from 'react';
import { setTitle } from '../helpers/MetaTag';
import MainBanner from "../components/sections/home/MainBanner";
import FeatureSlider from "../components/sections/home/FeatureSlider";
import About from "../components/sections/home/About";
import Statistics from "../components/sections/home/Statistics";
import Consultation from "../components/sections/home/Consultation";
import AreaOfIntervention from "../components/sections/home/AreaOfIntervention";
import GetStarted from "../components/sections/home/GetStarted";
import OnlineFeatures from "../components/sections/home/OnlineFeatures";
import Register from "../components/sections/home/Register";
import SmartPortal from "../components/sections/home/SmartPortal";
import Testimonial from "../components/sections/home/Testimonial";
import Consultants from "../components/sections/home/Consultants";


const Home = () => {
  setTitle('Prontopsy | Home');

  return (
    <>
      <MainBanner />
      <FeatureSlider />
      <About />
      <Statistics />
      <Consultation />
      <AreaOfIntervention />
      <Consultants />
      <GetStarted />
      <OnlineFeatures />
      <Testimonial />
      <SmartPortal />
      <Register />
    </>
  );
};

export default Home;