import React from 'react';
import StatisticsItem from "../../single/StatisticsItem";

const Statistics = () => {
  const statisticsData = {
    title: "We are the First Smart <span class='text-prontopsy-pink'>Psychology Portal in the World</span>",
    subtitle: "<p>We are the first global psychological consultation portal, open 24 hours a day, 7 days a week and 365 days a year, providing professional online support worldwide. We offer immediate and prompt assistance for all your psychological needs, with 24/7 access to thousands of consultants through chat, audio or video calls. We are always here for you, ready to offer the support you need.</p>",
    statistics: [
      { _id: 1, name: "Consultants", value: "66" },
      { _id: 2, name: "Areas Of Intervention", value: "26" },
      { _id: 3, name: "Consultings Completed", value: "568" },
      { _id: 4, name: "Satisfied Customers", value: "540" },
    ]
  }

  return (
    <>
      <section className="relative py-24 flex items-center bg-prontopsy-blue">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {statisticsData?.title && (
              <h2 className="text-5xl text-white font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: statisticsData?.title }}></h2>
            )}
            {statisticsData?.subtitle && (
              <div className="text-lg text-white space-y-4 mt-10 font-light" dangerouslySetInnerHTML={{ __html: statisticsData?.subtitle }}></div>
            )}
          </div>
          {statisticsData?.statistics?.length > 0 && (
            <div className="grid grid-cols-4 gap-10 mt-16">
              {statisticsData?.statistics?.map((item, index) => (
                <StatisticsItem key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Statistics;