import React from 'react';
import Image from "../elements/Image";
import { classNames } from "../../helpers/classNames";

const SmartPortalItem = ({ data, index, length }) => {
  let rightShiftCount = length - index - 2;
  let rightShift = -rightShiftCount * 80;

  return (
    <>
      <div className="relative py-6 flex items-center bg-prontopsy-yellow w-full max-w-lg" style={{ marginLeft: rightShift }}>
        {data?.icon && (
          <div className="w-20 aspect-square overflow-hidden flex-shrink-0 mx-auto bg-prontopsy-blue rounded-full p-4 -ml-10">
            <Image
              src={data?.icon}
              alt={""}
              effect={"blur"}
              className={"object-contain"}
            />
          </div>
        )}
        <div className="w-full flex-shrink space-y-2 px-10">
          {data.name && (<h4 className="font-UnisonPro font-bold italic text-xl text-prontopsy-pink !leading-[1.1]" dangerouslySetInnerHTML={{ __html: data.name }}></h4>)}
          {data.description && (<div className="text-lg text-black" dangerouslySetInnerHTML={{ __html: data.description }}></div>)}
        </div>
      </div>
    </>
  );
};

export default SmartPortalItem;