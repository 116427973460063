import React from 'react';
import RatingStars from "./RatingStars";

const Rating = ({ data }) => {
  return (
    <>
      <div className="relative w-full flex flex-col items-center space-y-1">
        <div className="flex items-center space-x-2">
          <RatingStars data={data?.value} />
          <div className="w-5 aspect-square flex-shrink-0 bg-prontopsy-blue flex items-center justify-center !leading-none text-xs text-white rounded">{data.value}</div>
        </div>
        <div className="text-sm text-neutral-700">Based on <span className="font-medium text-prontopsy-blue">{data.reviews}</span> review(s)</div>
      </div>
    </>
  );
};

export default Rating;