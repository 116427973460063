import React from 'react';
import { classNames } from "../../helpers/classNames";

const Favorite = ({
  name,
  inputClass,
  checkClass,
  isChecked,
  ...props
}) => {
  return (
    <>
      <div className="relative overflow-hidden flex items-center">
        <input type="checkbox"
          name={name}
          className={classNames("peer absolute top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer border-none", inputClass)}
          defaultChecked={isChecked}
        />
        <div className={classNames("transition-all duration-200 fa-light w-8 aspect-square flex items-center justify-center text-2xl text-neutral-500 peer-checked:font-bold peer-checked:text-prontopsy-red", checkClass)}>
          <i className="not-italic fa-fw fa-heart"></i>
        </div>
      </div>
    </>
  );
};

export default Favorite;