import React from 'react';
import { Link } from 'react-router-dom';
import { classNames } from "../../helpers/classNames";

const Button = ({
  buttonEffect = "",
  buttonClasses,
  buttonType,
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonFunction = () => { },
  buttonHasLink,
  buttonLink,
  buttonTarget,
  isDisable = false,
  ...props
}) => {



  return (
    <>
      {buttonHasLink ?
        (
          <Link to={buttonLink}
            className={classNames(
              "relative overflow-hidden flex justify-center items-center gap-2 bg-prontopsy-pink text-white rounded-md text-lg h-10 py-0 px-4 transition-all duration-200", buttonClasses, isDisable ? "pointer-events-none opacity-75" : ""
            )}
            target={buttonTarget}
          >
            {buttonIconPosition === 'left' &&
              <i className={classNames("fa-fw", buttonIcon)}></i>
            }
            {buttonLabel &&
              <span className={classNames("text-sm transition-all duration-200", buttonLabelClasses)}>{buttonLabel}</span>
            }
            {buttonIconPosition === 'right' &&
              <i className={classNames("fa-fw", buttonIcon)}></i>
            }
          </Link>
        ) :
        (
          <button
            type={buttonType}
            className={classNames(
              "relative overflow-hidden flex justify-center items-center gap-2 bg-prontopsy-pink text-white rounded-md text-lg h-10 py-0 px-4 transition-all duration-200", buttonClasses, isDisable ? "pointer-events-none opacity-75" : ""
            )}
            onClick={buttonFunction}
            disabled={isDisable}
          >
            {buttonIconPosition === 'left' &&
              <i className={classNames("fa-fw", buttonIcon)}></i>
            }
            {buttonLabel &&
              <span className={classNames("text-sm transition-all duration-200", buttonLabelClasses)}>{buttonLabel}</span>
            }
            {buttonIconPosition === 'right' &&
              <i className={classNames("fa-fw", buttonIcon)}></i>
            }
          </button>
        )
      }
    </>
  );
};

export default Button;