import React from 'react';
import SmartPortalItem from "../../single/SmartPortalItem";

import IconDirectAccess from "../../../assets/images/icon-portal-direct-access.webp";
import IconOnBooking from "../../../assets/images/icon-portal-on-booking.webp";

const SmartPortal = () => {
  const smartPortalData = {
    title: "First Smart Psychology Portal in the World",
    consultation: [
      {
        _id: 1,
        styleType: 1,
        icon: IconDirectAccess,
        name: "Direct Access",
        description: "<p>Request an online consultation now, in real time. A psychologist from our team will be there to meet your needs, 24/7.</p>"
      },
      {
        _id: 2,
        styleType: 2,
        icon: IconOnBooking,
        name: "On Booking",
        description: "<p>Book your online appointment and get a personalized psychological consultation, fitting your available schedule. Our highly qualified team is always ready to provide the support you need.</p>"
      },
    ]
  }
  return (
    <>
      <section className="relative py-20 flex items-center bg-prontopsy-pink">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="grid grid-cols-2 gap-20">
            <div className="flex items-center">
              {smartPortalData?.title && (
                <h2 className="text-6xl text-white font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: smartPortalData?.title }}></h2>
              )}
            </div>
            <div className="relative">
              {smartPortalData?.consultation?.length > 0 && (
                <div className="flex flex-wrap gap-8">
                  {smartPortalData?.consultation?.map((item, index) => (
                    <SmartPortalItem key={index} data={item} index={index} length={smartPortalData?.consultation?.length} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SmartPortal;