import React from 'react';
import GetStartedItem from "../../single/GetStartedItem";
import IconGettingStarted from "../../../assets/images/icon-getting-started-is-easy.webp";
import IconAreaOfIntervention from "../../../assets/images/icon-choose-an-area-of-intervention.webp";
import IconConnectPsychologists from "../../../assets/images/icon-connect with psychologists.webp";

const GetStarted = () => {
  const getStartedData = {
    title: "Getting<br/><span class='text-prontopsy-pink'>Started Is Easy</span>",
    subtitle: "<p>These services are provided by our team of highly qualified and experienced psychologists and therapists.</p>",
    getStarted: [
      {
        _id: 1,
        icon: IconGettingStarted,
        styleType: 1,
        name: "Getting<br/>Started Is Easy",
        description: "<p>Sign up with Prontopsy and create your personal profile for free! We will only ask you for the essential data to offer you a professional and quality service with respect for all the privacy you need.</p>"
      },
      {
        _id: 2,
        icon: IconAreaOfIntervention,
        styleType: 2,
        name: "Choose an area<br/>of intervention",
        description: "<p>Sign up with Prontopsy and create your personal profile for free! We will only ask you for the essential data to offer you a professional and quality service with respect for all the privacy you need.</p>"
      },
      {
        _id: 3,
        icon: IconConnectPsychologists,
        styleType: 1,
        name: "Connect with<br/>Psychologists",
        description: "<p>Call immediately your favorite psychologist or book your session and start receiving professional psychological support through chat, audio, or video call from the comfort of your own home.</p>"
      },
    ]
  }
  return (
    <>
      <section className="relative py-24 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full mx-auto max-w-6xl text-center">
            {getStartedData?.title && (
              <h2 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: getStartedData?.title }}></h2>
            )}
            {getStartedData?.subtitle && (
              <div className="text-lg text-neutral-700 space-y-4 mt-6 max-w-2xl mx-auto" dangerouslySetInnerHTML={{ __html: getStartedData?.subtitle }}></div>
            )}
          </div>
          {getStartedData?.getStarted?.length > 0 && (
            <div className="grid grid-cols-3 mt-16 pb-12">
              {getStartedData?.getStarted?.map((item, index) => (
                <GetStartedItem key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default GetStarted;