import React, { useEffect, useRef } from 'react';
import { register } from 'swiper/element/bundle';
import TestimonialItem from "../../single/TestimonialItem";
import Button from "../../form/Button";
import ImageUser01 from "../../../assets/images/user-01.webp";
import ImageUser02 from "../../../assets/images/user-02.webp";
register();

const Testimonial = () => {
  const swiperElRef = useRef(null);

  useEffect(() => {
    const swiperEl = document.querySelector('swiper-container.testimonialSlider');
    const swiperParams = {
      loop: true,
      navigation: {
        nextEl: '.testimonialSlider-next',
        prevEl: '.testimonialSlider-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          spaceBetween: 0,
          centeredSlides: true,
          loop: true
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 0,
          centeredSlides: false,
          loop: true
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 0,
          centeredSlides: false,
          loop: false
        },
      },
      on: {
        init() { },
      },
    };
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }, []);

  const testimonialData = {
    title: "What<br/><span class='text-prontopsy-pink'>Our Client Say</span>",
    testimonial: [
      {
        _id: 1,
        name: "George",
        image: ImageUser01,
        description: "<p>Aenean condimentum malesuada tellus quis viverra. Sed eu tortor in elit maximus malesuada in vitae massa.Quisque at purus non purus varius mollis.In facilisis in enim nec vehicula.Pellentesque scelerisque dictum velit.Pellentesque tempus magna hendrerit odio maximus, eu malesuada eros auctor.</p><p>Tincidunt elit.Phasellus felis magna, luctus ac dignissim</p>",
      },
      {
        _id: 2,
        name: "Alexander",
        image: ImageUser02,
        description: "<p>Duis cursus tincidunt nisi, eget sollicitudin lacus feugiat eget. Fusce nisi eros, efficitur vitae lorem nec, feugiat finibus massa. In sit amet enim vestibulum turpis ultrices cursus. Praesent pulvinar leo lorem, vitae lacinia tellus dictum quis.</p><p>Aenean porttitor dui in luctus ultricies.In sagittis metus a urna vehicula.</p>",
      },
      {
        _id: 3,
        name: "George",
        image: ImageUser01,
        description: "<p>Aenean condimentum malesuada tellus quis viverra. Sed eu tortor in elit maximus malesuada in vitae massa.Quisque at purus non purus varius mollis.In facilisis in enim nec vehicula.Pellentesque scelerisque dictum velit.Pellentesque tempus magna hendrerit odio maximus, eu malesuada eros auctor.</p><p>Tincidunt elit.Phasellus felis magna, luctus ac dignissim</p>",
      },
      {
        _id: 4,
        name: "Alexander",
        image: ImageUser02,
        description: "<p>Duis cursus tincidunt nisi, eget sollicitudin lacus feugiat eget. Fusce nisi eros, efficitur vitae lorem nec, feugiat finibus massa. In sit amet enim vestibulum turpis ultrices cursus. Praesent pulvinar leo lorem, vitae lacinia tellus dictum quis.</p><p>Aenean porttitor dui in luctus ultricies.In sagittis metus a urna vehicula.</p>",
      },
    ]
  }
  return (
    <>
      <section className="relative py-24 flex items-center">
        <div className="w-full px-4 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-8xl mx-auto">
          <div className="w-full text-center">
            {testimonialData?.title && (
              <h2 className="text-5xl text-prontopsy-blue font-UnisonPro italic font-bold !leading-[1.1]" dangerouslySetInnerHTML={{ __html: testimonialData?.title }}></h2>
            )}
          </div>
          {testimonialData?.testimonial?.length > 0 && (
            <div className="relative w-full max-w-7xl mx-auto mt-10">
              <swiper-container
                ref={swiperElRef}
                class="testimonialSlider -mx-10"
                init="false"
              >
                {testimonialData?.testimonial?.map((item) => (
                  <swiper-slide key={item._id} className="">
                    <TestimonialItem data={item} />
                  </swiper-slide>
                ))}
              </swiper-container>
              {/* <div className="relative flex justify-center z-0 before:content-[''] before:absolute before:w-full before:top-7 before:left-0 before:border-t-2 before:border-neutral-200 before:z-[-1]">
                <div className="flex justify-center space-x-2 bg-white px-4">
                  <Button
                    buttonClasses={'testimonialSlider-prev !px-0 aspect-square !rounded-full !bg-transparent !text-neutral-700 border border-neutral-700 !h-14 !text-2xl'}
                    buttonType={'button'}
                    buttonIcon={"fa-light fa-chevron-left"}
                    buttonIconPosition={"left"}
                  />
                  <Button
                    buttonClasses={'testimonialSlider-next !px-0 aspect-square !rounded-full !bg-transparent !text-neutral-700 border border-neutral-700 !h-14 !text-2xl'}
                    buttonType={'button'}
                    buttonIcon={"fa-light fa-chevron-right"}
                    buttonIconPosition={"left"}
                  />
                </div>
              </div> */}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Testimonial;